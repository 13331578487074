/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
  font-family: 'Corisande';
  src: url('fonts/corisande/corisande-light.woff2') format('woff2'),
  url('fonts/corisande/corisande-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Corisande';
  src: url('fonts/corisande/corisande-regular.woff2') format('woff2'),
  url('fonts/corisande/corisande-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Corisande';
  src: url('fonts/corisande/corisande-bold.woff2') format('woff2'),
  url('fonts/corisande/corisande-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.body {
  @apply text-gray-700
}

@layer base {
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
  h5 {
    @apply text-xl;
  }
  h6 {
    @apply text-lg;
  }
}

@layer components {
  .button {
    @apply relative inline-flex justify-center items-center py-2 px-4 border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 w-full px-4 py-2 rounded-md text-sm border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-brand-500;
  }
}

@layer utilities {
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: none;
    background-color: #504E4E;
  }
}

/* Cool background */
.cool-background {
  @apply relative h-screen overflow-y-auto bg-gray-800 overflow-hidden before:block before:fixed before:w-[25vw] before:h-[200vh] before:bg-white before:top-1/2 before:left-1/2 before:z-0 before:transform before:origin-center before:rotate-[-15deg] before:translate-x-40 before:-translate-y-1/2 before:opacity-[0.13] before:pointer-events-none after:block after:fixed after:w-[25vw] after:h-[200vw] after:bg-white after:top-1/2 after:left-1/2 after:z-0 after:transform after:origin-center after:rotate-[-65deg] after:-translate-x-1/2 after:-translate-y-1/2 after:opacity-5 after:pointer-events-none
}

.btn-icon {
  @apply h-5 w-5
}

.btn-primary-color {
  @apply bg-brand-500 hover:bg-brand-600 focus:ring-brand-400
}

.btn-secondary-color {
  @apply bg-gray-500 hover:bg-gray-600 focus:ring-gray-400
}

.page-container {
  @apply my-4 mx-4 md:my-5 md:mx-8
}

.title-page {
  @apply my-5 mb-16 mx-4 md:mx-8 text-gray-800
}

.subheader{
  @apply text-gray-500 text-sm
}

.inset-ring {
  box-shadow: inset 0 0 0 8px #E8E8E8;
}

.content-block {
  @apply mb-8
}

.content-h3 {
  @apply mb-2 text-lg font-bold
}

.content-p,
.content-ul {
  @apply mb-4 text-base
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#imago-light  { animation: light 1s infinite linear; }

/* Legacy Bootstrap 3 class used inside Storybook */
.container-fluid {
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

/* Use @support due backdrop-blur lack support on Firefox :( */
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .fx-glass {
    @apply bg-opacity-60 backdrop-filter backdrop-blur-sm
  }
}
